<template>
  <div class="corpyrights">© 2017 - 2021 {{ appName }} All rights reserved</div>
</template>
<script>
export default {
  name: "SimpleFooter",
  components: {  },
  data() {
    return {
     
    }
  },
  computed:{
    appName() {
      return window.configObj.appName
    }
  },
  methods: {
  }
};
</script>
<style lang="scss" scoped>
  .corpyrights{
    position: fixed;
    bottom: 10px;
    text-align: center;
    font-size: 12px;
    width: 100%;
    left: 0;
  }
</style>